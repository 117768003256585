import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { getRandomNumber } from './utils/getRandomNumber';
import { splitText } from './utils/splitText';
gsap.registerPlugin(ScrollTrigger);
const scrollContainer = document.getElementById('scroll-container');
const title = document.getElementById('title');
const maskedTitle = document.getElementById('masked-title');
const maskedTitleContainer = document.getElementById('masked-title-container');
const viewportCenterPosition = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
const viewportMaximum = Math.max(window.innerWidth, window.innerHeight);
const characterIntroDuration = 1;
const explosionDuration = 10;
function getCharacterIntroTimeline(character) {
    return gsap.timeline().from(character, { x: getRandomNumber(-window.innerWidth / 4, window.innerWidth / 4), y: getRandomNumber(-window.innerHeight / 4, window.innerHeight / 4), rotate: getRandomNumber(-45, 45), scale: getRandomNumber(0.5, 2.5), duration: characterIntroDuration, ease: 'back.inOut' });
}
function getCharacterExplosionConfig(character) {
    const { left, width, top, height } = character.getBoundingClientRect();
    const initialDistanceFromCenter = {
        x: Math.abs(viewportCenterPosition.x - (left + width / 2)),
        y: Math.abs(viewportCenterPosition.y - (top + height / 2)),
    };
    const xMultiplier = viewportCenterPosition.x < left ? 1 : -1;
    const yMultiplier = viewportCenterPosition.y < top ? 1 : -1;
    const maskedContainerRadius = {
        start: Math.sqrt(initialDistanceFromCenter.x ** 2 + initialDistanceFromCenter.y ** 2),
        end: viewportMaximum,
    };
    const angleInRadians = Math.atan(initialDistanceFromCenter.y / initialDistanceFromCenter.x);
    const endPosition = {
        x: maskedContainerRadius.end * Math.cos(angleInRadians) * 0.7,
        y: maskedContainerRadius.end * Math.sin(angleInRadians) * 0.7,
    };
    const translationValues = {
        x: (endPosition.x - initialDistanceFromCenter.x) * xMultiplier,
        y: (endPosition.y - initialDistanceFromCenter.y) * yMultiplier,
        scale: getRandomNumber(1, 2),
        rotate: getRandomNumber(-90, 90),
    };
    return { ...translationValues, duration: explosionDuration, delay: (maskedContainerRadius.start / viewportMaximum) * explosionDuration };
}
function getExplosionTimeline(characters, maskedCharacters) {
    const timeline = gsap.timeline();
    timeline.to(maskedTitleContainer, { clipPath: `circle(100% at 50% 50%)`, duration: explosionDuration, ease: 'none' });
    maskedCharacters.forEach((character, index) => {
        const config = getCharacterExplosionConfig(character);
        timeline.to([character, characters[index]], { ...config, ease: 'none' }, 0);
    });
    return timeline;
}
if (title && maskedTitle) {
    const characterElements = [...title.children].map((child) => splitText(child)).reduce((previous, current) => previous.concat(current));
    const maskedCharacterElements = [...maskedTitle.children].map((child) => splitText(child)).reduce((previous, current) => previous.concat(current));
    const titleTimeline = gsap.timeline({ scrollTrigger: {
            trigger: scrollContainer,
            start: 'top top',
            end: () => `+=${window.innerHeight * 3}`,
            scrub: true,
            pin: true,
        } });
    titleTimeline.from(scrollContainer, { y: 0, duration: 0.1 });
    characterElements.forEach((characterElement) => {
        titleTimeline.add(getCharacterIntroTimeline(characterElement));
    });
    titleTimeline.add(getExplosionTimeline(characterElements, maskedCharacterElements));
    document.body.classList.add('is-initialized');
}
